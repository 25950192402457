import { UPDATE_CART, EMPTY_CART } from './actionTypes';

export const updateCart = cartProducts => dispatch => {
  let productQuantity = cartProducts.reduce((sum, p) => {
    sum += p.quantity;
    return sum;
  }, 0);

  let totalPrice = cartProducts.reduce((sum, p) => {
    sum += p.price * p.quantity;
    console.log('Sum', sum)
    return sum;
  }, 0);

  totalPrice = totalPrice.toFixed(2);

  let cartTotal = {
    productQuantity,
    totalPrice,
  };

  dispatch({
    type: UPDATE_CART,
    payload: cartTotal
  });
};

export const emptyCart = () => dispatch => {
  return dispatch({
    type: EMPTY_CART
  })
}

