import React from 'react';

const ShelfHeader = props => {
  return (
    <div className="shelf-container-header">
      <small className="products-found">
        <span>{props.productLength} Product(s) found.</span>
      </small>
    </div>
  );
};


export default ShelfHeader;
