import React, { Component } from 'react'

export class Rating extends Component {
  render() {
    var rating=[];
    for(var i=1; i<= 5; i++){
      rating.push(i);
    }
    const Ratings = rating.map(x=> {
      return(
        <span className="rating active" key={x}><a href style={{cursor:"pointer"}}>{x}</a></span>
      )
    });
    return (
      <div>
        {Ratings}
      </div>
    )
  }
}

export default Rating
