import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, RESET_CART } from './actionTypes';

export const loadCart = products => ({
  type: LOAD_CART,
  payload: products
});

export const addProduct = (product) => {
return   (
  {
  type: ADD_PRODUCT,
  payload: product,
})};

export const removeProduct = product => ({
  type: REMOVE_PRODUCT,
  payload: product
});

export const resetCart = () => dispatch => {
  return dispatch({
    type: RESET_CART,
  })
}
