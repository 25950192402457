import React, { Component } from 'react'

export class Success extends Component {
  render() {
    return (
      <div>
           <div className="range-bar">
            <div className="step active"><span>Details</span></div>
            <div className="step active"><span>Order Confirmation</span></div>
            <div className="step active"><span>Payment</span></div>
            <div className="step active"><span>Order Placed</span></div>
          </div>
          <div className="d-flex height-100v justify-content-center align-items-center flex-column">
            <img width="300" alt="success" height="300" src="https://image.freepik.com/free-vector/vector-illustration-concept-rocket-launch_65395-130.jpg" />
            <h3>Order Placed Successfully</h3>
            <a href="/menu">Continue Shopping</a>
          </div>
      </div>
    )
  }
}

export default Success;
