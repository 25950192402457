import React, { Component } from 'react';
import './login.scss';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e, email, password) => {
    e.preventDefault();
    if(email === 'admin' && password === 'admin') this.props.history.push('./menu');
    else {
      this.setState({error: 'Credentials not correct'})
    }
  }

  render() {
    const { email, password, error } = this.state;
    return (
      <main className="signin-container">
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="align-self-center">
            <h2>Sign In</h2>
            <form onSubmit={(e) => this.handleSubmit(e, email, password)}>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={this.handleChange}
              />
              <label>
                {' '}
                <input type="checkbox" /> Remember
              </label>
              {error && <div className="alert alert-danger">{error}</div>}
              <div>
                <button type="submit">Sign In</button>
              </div>
            </form>
            <div className="account-link-cont">
              <button>Forgot Password?</button>
              <button>Have an Account?</button>
            </div>
          </div>
        </div>

        <footer className="row" />
      </main>
    );
  }
}

export default Login;
