import React, { Component } from 'react';
import Shelf from '../Shelf';
// import Filter from '../Shelf/Filter';

class Menu extends Component {
  render() {
    return (
      <React.Fragment>
        <main>
          {/* <Filter /> */}
          <Shelf />
        </main>
      </React.Fragment>
    );
  }
}

export default Menu;
