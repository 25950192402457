import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {connect} from 'react-redux';
import {resetCart} from '../../services/cart/actions';
import {emptyCart} from '../../services/total/actions'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';

// width: calc(100% - 30px);
//     margin-bottom: 15px;
//     padding: 0px 15px;
//     border: 1px solid #e4e4e4;
//     height: 40px;
//     border-radius: 3px;
//     text-align: center;
//     font-size: 14px;
const createOptions = () => {
  return {
    style: {
      base: {
        height: '40px',
        fontSize: '14px',
        textAlign: 'center',
        padding: '0px 15px',
        border: '1px solid #e4e4e4',
        borderRadius: '3px',
        letterSpacing: '0.025em',
        background: '#fff',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};


export class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
    };
  }

  handleSubmit = async (evt) => {
    evt.preventDefault();
    let { token } = await this.props.stripe.createToken({ name: "Name" });
    let response = await fetch(`http://localhost:9890/api/payment/${this.props.location.state}`, {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: token.id,
    });
    if (response.ok) {
      console.log("Purchase Complete!", response);
      this.props.resetCart();
      this.props.emptyCart();
      this.props.history.push('./order-placed')
    } else {
      console.log('Purchased Canceled')
    }
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };


  render() {
    console.log('Cart Total', this.props)
    return (
      <div>
        <div className="range-bar">
          <div className="step active"><span>Details</span></div>
          <div className="step active"><span>Order Confirmation</span></div>
          <div className="step active"><span>Payment</span></div>
          <div className="step"><span>Order Placed</span></div>
        </div>

        <div className="checkout flex-wrap">

          <form onSubmit={this.handleSubmit} style={{ width: '100%' }}>
            <div className="split-form d-flex flex-wrap col-12">
              <div className="col-4">
                <label>
                  Card number
                </label>

              </div>
              <div className="col-6">
                <CardNumberElement
                  {...createOptions()}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-4">
                <label>
                  Expiration date

                </label>
              </div>
              <div className="col-6">
                <CardExpiryElement
                  {...createOptions()}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-4">
                <label>
                  CVC

                </label>
              </div>

              <div className="col-6">
                <CardCVCElement {...createOptions()} onChange={this.handleChange} />
              </div>


              <div className="col-4">
                <label>
                  Postal code
                </label>
              </div>

              <div className="col-6">
                <input
                  name="name"
                  type="text"
                  placeholder="94115"
                  className="StripeElement"
                  required
                />
              </div>
            </div>
            <div className="error" role="alert">
              {this.state.errorMessage}
            </div>
            <div className="d-flex">
              <div className="col-4">&nbsp;</div>
              <div className="col-6">
                <button>Pay ${this.props.location.state}</button>
                <button onClick={() => this.props.history.goBack()}>Back</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    )
  }
}

Payment = withRouter(Payment)
Payment = connect(null, {resetCart, emptyCart})(Payment)

export default injectStripe(Payment)
