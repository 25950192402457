import { FETCH_PRODUCTS } from './actionTypes';

const initialState = {
  products: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload.rows,
        productLength: action.payload.count
      };
    default:
      return state;
  }
}
